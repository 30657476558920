<template>
  <div style="display:inline-block;width: 240px;">
    <el-row>
      <el-col :span="24">
        <el-date-picker
          class="year-picker"
          type="year"
          format="yyyy"
          value-format="yyyy"
          :clearable="false"
          v-model="year"
        ></el-date-picker>
        <el-date-picker
          popper-class="month-picker"
          class="month-picker"
          type="month"
          format="MMMM"
          value-format="MMMM"
          :clearable="false"
          v-model="month"
        ></el-date-picker>
      </el-col>
    </el-row>
    <ul class="date-list">
      <li class="date-list"><strong>Date</strong></li>
      <li class="date-list" v-for="(item, index) in momenttime" >
        <el-tooltip placement="top" v-if="item.isHoliday" effect="light">
          <div slot="content">{{ item.holidayDescription}}</div>
          <strong  v-bind:class="{ 'red-text': item.isWeekend || item.isHoliday }" >{{ item.date }} {{ item.isHalfDay? '(half-day)':''}}</strong>
        </el-tooltip>
        <strong v-else  v-bind:class="{ 'red-text': item.isWeekend || item.isHoliday }" >{{ item.date }} {{ item.isHalfDay? '(half-day)':''}}</strong>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'MonthlyDate',
  data () {
    return {
      month: moment().locale('en').format('MMMM'),
      year: moment().locale('en').format('yyyy'),
    }
  },
  props:{
    holiday: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    update(){
      const month = this.month
      const year = this.year
      this.$emit('update', { month, year })
    }
  },
  computed: {
    momenttime () {
      let numberOfDay = moment(`${this.month} ${this.year}`).locale('en').daysInMonth()
      let dayInMonth = []
      for (let i = 1; i <= numberOfDay; i++){
        let theDate = moment(`${this.month} ${this.year}`).locale('en').startOf('month').date(i)

        const isHoliday = this.holiday.find(x => x.calendarDate === theDate.format('yyyy-MM-DD'))

        let item = {
          date: theDate.format('DD/MM/yyyy ddd'),
          isWeekend: theDate.day() === 0 || theDate.day() === 6,
        }

        if (isHoliday) {
          item.holidayDescription = isHoliday.description
          if(isHoliday.fullDay){
            item.isHoliday = true
          }else{
            item.isHoliday = true
            item.isHalfDay = true
          }
        }

        dayInMonth.push(item)
      }

      this.update()
      return dayInMonth
    }
  }
}
</script>

<style>
ul.date-list  {
  list-style-type: none;
  margin: 65px 0 0 0;
  padding: 0 0 0 10px;
}

li.date-list {
  margin: 16px;
  font-size: 14px;
}

.red-text {
  color: red;
}

.el-month-table td.today:not(.disabled) .cell{
  font-weight: normal;
}

.el-month-table td.current:not(.disabled) .cell{
  font-weight: 700;
}

.el-year-table td .cell:hover, .el-year-table td.current:not(.disabled) .cell{
  font-weight: 700;
}

.el-year-table td.today .cell{
  font-weight: normal;
}


.month-picker .el-date-picker__header{
  display: none;
}

.el-date-editor.el-input.month-picker {
  width: 140px;
  margin: 0px 10px 0px 10px;
}
.el-date-editor.el-input.year-picker {
  width: 140px;
  margin: 10px 10px 10px 10px;
}
</style>
